import * as Sentry from '@sentry/browser'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import { find, groupBy, has, some } from 'lodash-es'
import {
  getPendingPlanInvitationsForBoard,
  getPendingPlanInvitationsForEmail,
  removePlanInvitationForOutsiders
} from '@/services/plan-invitations-api.service.js'
import {
  acceptInvitation,
  acceptInvitationForOutsiders,
  getPendingInvitationsForBoard,
  getPendingInvitationsForEmail
} from '@/services/invitations-api.service.js'
import { getPeopleByEmail } from '@/services/people-api.service.js'
import { sendPlanInviteToOutsiders } from '@/services/invitations.service.js'
import { fetchAndActivate, getRemoteConfig, getValue } from 'firebase/remote-config'
import { logout } from '@/services/account-api.service'
import { isMFAEnabled, restoreMfaVerifiedClaim } from '@/services/account-api.service'

// import { AUTH_PROVIDERS } from '@/lib/AuthProviders'

const state = {
  userObj: null,
  userMetaData: null,
  invitations: null,
  planInvitations: {},
  userPlanInvites: null,
  backupOriginalUser: null,
  photoURL: '',
  signInError: null,
  isAppLoading: false,
  displayName: '',
  isMFAEnabled: false
}

// check if user has enabled multi factor authentication
const checkIfMFAEnabled = (user) => {
  if (!user) return false
  const enrolledFactors = user?.multiFactor.enrolledFactors
  return enrolledFactors.length > 0
}

const mutations = {
  setIsAppLoading(state, status) {
    state.isAppLoading = status
  },
  setUser(state, _user) {
    if (!_user) return

    state.userObj = _user
    state.photoURL = _user.photoURL
    state.displayName = _user.displayName
    state.isMFAEnabled = checkIfMFAEnabled(_user)
  },
  setInvitations(state, invitations) {
    state.invitations = invitations
  },
  setUserPlanInvites(state, invitations) {
    state.userPlanInvites = invitations
  },
  setPlanInvitations(state, invitations) {
    state.planInvitations = invitations
  },
  updatePlanInvitations(state, { planId, invitation }) {
    if (has(state.planInvitations, planId)) {
      state.planInvitations[planId].push(invitation)
    } else {
      state.planInvitations[planId] = [invitation]
    }
  },
  removePlanInvite(state, { planId, recipient }) {
    state.planInvitations[planId] = state.planInvitations[planId].filter(
      (plan) => plan.recipient !== recipient
    )
  },
  setSignInError(state, error) {
    state.signInError = error
  }
}

const getters = {
  /**
   * Returns true if the current user is admin
   * @param {String} boardId Passed from the calling component.
   * @returns
   */
  isAppLoading: (state) => {
    return state.isAppLoading
  },
  isAdmin: (state, getters, rootState) => (boardId) => {
    const targetBoard = find(rootState.board.boards, (board) => {
      return board.boardId === boardId
    })
    if (!targetBoard) return false
    if (targetBoard.accessLevels) {
      return some(targetBoard.accessLevels, (access) => {
        return access.uid === state.userObj.uid && access.accessLevel === 'admin'
      })
    }
    return false
  },

  isPlanCreator: (state, getters, rootState) => (boardId) => {
    const targetBoard = find(rootState.board.boards, (board) => {
      return board.boardId === boardId
    })
    if (!targetBoard) return false
    return (
      (targetBoard.owners?.length === 1 && targetBoard.owners[0] === state.userObj.uid) ||
      targetBoard.createdBy === state.userObj.uid
    )
  },

  isAdminOrPlanCreator: (state, getters) => (boardId) => {
    return getters.isAdmin(boardId) || getters.isPlanCreator(boardId)
  },

  isExecutive: (state, getters, rootState) => (boardId) => {
    const targetBoard = find(rootState.board.boards, (board) => {
      return board.boardId === boardId
    })
    if (!targetBoard) return false
    if (targetBoard.accessLevels) {
      return some(targetBoard.accessLevels, (access) => {
        return access.uid === state.userObj.uid && access.accessLevel === 'executive'
      })
    }
    return false
  },

  isFinance: (state, getters, rootState) => (boardId) => {
    const targetBoard = find(rootState.board.boards, (board) => {
      return board.boardId === boardId
    })
    if (!targetBoard) return false
    if (targetBoard.accessLevels) {
      return some(targetBoard.accessLevels, (access) => {
        return access.uid === state.userObj.uid && access.accessLevel === 'finance'
      })
    }
    return false
  },

  isHR: (state, getters, rootState) => (boardId) => {
    const targetBoard = find(rootState.board.boards, (board) => {
      return board.boardId === boardId
    })
    if (!targetBoard) return false
    if (targetBoard.accessLevels) {
      return some(targetBoard.accessLevels, (access) => {
        return access.uid === state.userObj.uid && access.accessLevel === 'hr'
      })
    }
    return false
  },

  isManager: (state, getters, rootState) => (boardId) => {
    const targetBoard = find(rootState.board.boards, (board) => {
      return board.boardId === boardId
    })
    if (!targetBoard) return false
    if (targetBoard.accessLevels) {
      return some(targetBoard.accessLevels, (access) => {
        return access.uid === state.userObj.uid && access.accessLevel === 'manager'
      })
    }
    return false
  },

  isInAccessGroup:
    (state, _, rootState) =>
    ({ boardId, accessGroup }) => {
      const targetBoard = find(rootState.board.boards, (board) => {
        return board.boardId === boardId
      })
      if (!targetBoard) return false

      if (targetBoard.accessLevels) {
        const access = targetBoard.accessLevels.find((access) => {
          return access.uid === state.userObj.uid
        })

        if (access) {
          return accessGroup.includes(access.accessLevel)
        }
      }
      return false
    },

  role: (state, getters, rootState) => (boardId) => {
    const targetBoard = find(rootState.board.boards, (board) => {
      return board.boardId === boardId
    })
    return find(targetBoard?.accessLevels, (access) => {
      return access.uid === getters.uid
    })?.accessLevel
  },

  user: (state) => {
    return state.userObj
  },

  uid: (state) => {
    return state.userObj?.uid || null
  },

  myEmail: (state) => {
    return state.userObj.email
  },

  /**
   * Return photoURL from user object
   * @returns {string|*}
   */
  userPic: (_state) => {
    if (_state.user) return _state.user.photoURL
    return ''
  },

  userDisplayName: (_state) => {
    return _state.displayName ?? ''
  },

  isLoggedIn: (_state) => {
    return _state.userObj !== null
  },
  hasPendingInvitations: (_state) => {
    return _state.invitations?.length > 0
  },
  hasUserPlanInvites: (_state) => {
    return _state.userPlanInvites?.length > 0
  },
  pendingPlanInvitationsByPlanId: (_state) => (planId) => {
    return _state.planInvitations?.[planId] || []
  },
  signInError: (_state) => {
    return _state.signInError
  },
  isMFAEnabled: (_state) => {
    return _state.isMFAEnabled ?? false
  }
}

const actions = {
  changeIsAppLoading({ commit }, status) {
    commit('setIsAppLoading', status)
  },
  async googleSignIn({ getters, dispatch, commit }, isSwitching = false) {
    commit('setSignInError', null)

    try {
      const provider = new firebase.auth.GoogleAuthProvider()
      console.log('🚀 ~ googleSignIn ~ provider:', provider)
      provider.setCustomParameters({
        prompt: 'select_account'
      })
      firebase.auth().useDeviceLanguage()

      await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)

      let result
      let restoreVerifiedStatus = false

      if (isSwitching) {
        // If the user is switching login methods, link Google to their existing account
        const user = firebase.auth().currentUser
        console.log('🚀 ~ googleSignIn ~ user:', user)
        if (!user) {
          throw new Error('No authenticated user found. Please sign in first.')
        }

        const idTokenResult = await user.getIdTokenResult(true) // Force refresh to get latest claims
        const mfaUser = await isMFAEnabled()
        // check if the user had MFA enabled, then we have to add the mfaVerified claim again
        if (mfaUser && !idTokenResult.claims.mfaVerified) restoreVerifiedStatus = true

        result = await user.linkWithPopup(provider)
        if (restoreVerifiedStatus) {
          await restoreMfaVerifiedClaim()
        }
        console.log('🚀 ~ googleSignIn ~ result:', result)
        console.log('Successfully linked Google account:', result.user)
      } else {
        // Regular Google sign-in flow
        result = await firebase.auth().signInWithPopup(provider)
        console.log('🚀 ~ googleSignIn ~ result:', result)
      }

      await dispatch('setUserAuth', result.user)
      await getters.user.getIdToken(true)

      if (!isSwitching && result.additionalUserInfo.isNewUser) {
        window.mixpanel.track('signup', { provider: 'google' })
      }

      return true
    } catch (error) {
      console.log('Sign-in error:', error)
      if (Sentry) Sentry.captureException(error)

      commit('setSignInError', error)
      return false
    }
  },

  async microsoftSignIn({ getters, dispatch, commit }, isSwitching = false) {
    commit('setSignInError', null)

    try {
      const provider = new firebase.auth.OAuthProvider('microsoft.com')
      firebase.auth().useDeviceLanguage()

      let result
      let restoreVerifiedStatus = false

      if (isSwitching) {
        // If user is switching login method, use linkWithPopup
        const user = firebase.auth().currentUser
        if (!user) {
          throw new Error('No authenticated user found. Please sign in first.')
        }
        const idTokenResult = await user.getIdTokenResult(true) // Force refresh to get latest claims
        const mfaUser = await isMFAEnabled()
        // check if the user had MFA enabled, then we have to add the mfaVerified claim again
        if (mfaUser && !idTokenResult.claims.mfaVerified) restoreVerifiedStatus = true

        result = await user.linkWithPopup(provider)
        if (restoreVerifiedStatus) {
          await restoreMfaVerifiedClaim()
        }
        console.log('Successfully linked Microsoft account:', result.user)
      } else {
        // Regular sign-in flow
        result = await firebase.auth().signInWithPopup(provider)
      }

      await dispatch('setUserAuth', result.user)
      await getters.user.getIdToken(true)

      if (!isSwitching && result.additionalUserInfo.isNewUser) {
        window.mixpanel.track('signup', { provider: 'microsoft' })
      }

      return true
    } catch (error) {
      console.log('Sign-in error:', error)
      if (Sentry) Sentry.captureException(error)

      commit('setSignInError', error)
      return false
    }
  },

  async oktaSignIn({ getters, dispatch, commit }, { providerId, isSwitching = false }) {
    commit('setSignInError', null)

    try {
      const provider = new firebase.auth.OAuthProvider(providerId)
      firebase.auth().useDeviceLanguage()

      let result
      let restoreVerifiedStatus = false

      if (isSwitching) {
        // If user is switching login method, use linkWithPopup
        const user = firebase.auth().currentUser
        if (!user) {
          throw new Error('No authenticated user found. Please sign in first.')
        }

        const idTokenResult = await user.getIdTokenResult(true) // Force refresh to get latest claims
        const mfaUser = await isMFAEnabled()
        // check if the user had MFA enabled, then we have to add the mfaVerified claim again
        if (mfaUser && !idTokenResult.claims.mfaVerified) restoreVerifiedStatus = true

        result = await user.linkWithPopup(provider)
        if (restoreVerifiedStatus) {
          await restoreMfaVerifiedClaim()
        }
        console.log('Successfully linked Okta account:', result.user)
      } else {
        // Regular sign-in flow
        result = await firebase.auth().signInWithPopup(provider)
      }

      await dispatch('setUserAuth', result.user)
      await getters.user.getIdToken(true)

      if (!isSwitching && result.additionalUserInfo.isNewUser) {
        window.mixpanel.track('signup', { provider: 'okta' })
      }

      return true
    } catch (error) {
      console.log('Sign-in error:', error)
      if (Sentry) Sentry.captureException(error)

      commit('setSignInError', error)
      return false
    }
  },
  async firebaseSamlSignIn({ getters, commit, dispatch }, { providerId }) {
    commit('setSignInError', null)
    console.log('Signing with firebase saml provider', providerId)

    try {
      const provider = new firebase.auth.SAMLAuthProvider(providerId)
      firebase.auth().useDeviceLanguage()

      const result = await firebase.auth().signInWithPopup(provider)

      await dispatch('setUserAuth', result.user)
      await getters.user.getIdToken(true)

      if (result.additionalUserInfo.isNewUser) {
        window.mixpanel.track('signup', { provider: 'saml' })
      }

      return true
    } catch (error) {
      console.log('sign in error', error)
      if (Sentry) Sentry.captureException(error)

      commit('setSignInError', error)
      return false
    }
  },
  async samlSignIn({ commit }, { providerId }) {
    console.log('🚀 ~ samlSignIn ~ providerId:', providerId)
    commit('setSignInError', null)

    try {
      const remoteConfig = getRemoteConfig(firebase.app())

      await fetchAndActivate(remoteConfig)
      const identityProviderConfig = JSON.parse(
        getValue(remoteConfig, providerId.replace('saml.', '')).asString()
      )

      if (identityProviderConfig.sso_login_url) {
        window.location.href = identityProviderConfig.sso_login_url
      } else throw new Error('SAML provider not found')

      return true
    } catch (error) {
      console.log('sign in error', error)
      if (Sentry) Sentry.captureException(error)

      commit('setSignInError', error)
      return false
    }
  },
  async customTokenSignIn({ getters, dispatch, commit }, token) {
    commit('setSignInError', null)

    try {
      firebase.auth().useDeviceLanguage()

      const result = await firebase.auth().signInWithCustomToken(token)

      await dispatch('setUserAuth', result.user)
      await getters.user.getIdToken(true)

      if (result.additionalUserInfo.isNewUser) {
        window.mixpanel.track('signup', { provider: 'custom_token' })
      }

      return true
    } catch (error) {
      console.log('sign in error', error)
      if (Sentry) Sentry.captureException(error)

      commit('setSignInError', error)
      return false
    }
  },
  async logout(context) {
    await logout()

    return firebase
      .auth()
      .signOut()
      .then(
        () => {
          // Sign-out successful.
          context.commit('setUser', null)
          localStorage.removeItem('always-on-fields')
          console.log('logout success')
        },
        (error) => {
          // An error happened.
          console.error(error)
          if (Sentry) Sentry.captureException(error)
        }
      )
  },
  async updateName({ commit }, { displayName }) {
    try {
      await firebase.auth().currentUser.updateProfile({
        displayName
      })
      commit('setUser', firebase.auth().currentUser)
    } catch (error) {
      console.error('error updating name', error)
      if (Sentry) Sentry.captureException(error)
    }
  },
  async setUserAuth(context, user) {
    context.commit('setUser', user)
  },
  async fetchPendingInvitations(context, { email }) {
    const invitations = await getPendingInvitationsForEmail({ email })
    const pendingInvitations = invitations.filter((invite) => !invite.ignore)
    context.commit('setInvitations', pendingInvitations)
  },
  async fetchPendingInvitationsForBoard(context, { boardId }) {
    return await getPendingInvitationsForBoard({ boardId })
  },
  async acceptInvitation(context, { boardId, invitationId }) {
    try {
      await acceptInvitation({ boardId, id: invitationId })

      await context.dispatch('fetchAllBoards', context.state.userObj.uid)
    } catch (e) {
      console.error(e)
    }
  },
  async fetchPersonFromMainBoard(context, { boardId }) {
    try {
      const people = await getPeopleByEmail({ boardId, email: context.getters.myEmail })
      return people[0] || null
    } catch (err) {
      console.error('fetchPersonFromMainBoard err', err)
    }
  },
  async acceptPlanInvitationForOutsiders(context, email) {
    try {
      await acceptInvitationForOutsiders({ email })
      // fetch all the boards again to show updated boards and plans
      await context.dispatch('fetchAllBoards', context.state.userObj.uid)
    } catch (err) {
      console.error('error occured while accepting plan invitations', err)
    }
  },
  // async deletePlanInvitationsForOutsiders(context, { planBoardId, boardId }) {
  //   if (!planBoardId) throw new Error('BoardId is undefined')
  //   try {
  //     await deletePlanInvitations({ planBoardId, boardId })
  //   } catch (e) {
  //     window.console.error('Error deleting plan invites from firebase: ', e)
  //     if (Sentry) Sentry.captureException(e)
  //   }
  // },
  async sendPlanInviteToOutsiders(
    context,
    {
      planName,
      planBoardId,
      boardId,
      invitedBy,
      inviterEmail,
      recipient,
      recipientName,
      accessLevel
    }
  ) {
    try {
      const invitation = {
        planName,
        planBoardId,
        boardId,
        invitedBy,
        inviterEmail,
        recipient,
        accessLevel,
        visited: false
      }

      context.commit('updatePlanInvitations', { planId: planBoardId, invitation })

      await sendPlanInviteToOutsiders({
        boardId,
        invitation,
        origin: window.location.origin,
        recipientName
      })
    } catch (err) {
      console.error('failed to send invite to an outsider', err)
      if (Sentry) Sentry.captureException(err)
    }
  },
  async removePlanInviteForOutsiders(context, { planBoardId, recipient }) {
    try {
      context.commit('removePlanInvite', { planId: planBoardId, recipient })
      await removePlanInvitationForOutsiders({ planBoardId, recipient })
    } catch (err) {
      console.error('failed to remove invite for an outsider', err)
      if (Sentry) Sentry.captureException(err)
    }
  },
  async fetchPendingPlanInvitations(context, { email }) {
    try {
      const invitations = await getPendingPlanInvitationsForEmail({ email })
      // only set state if querying for the logged in user
      if (email === context.getters.myEmail) context.commit('setUserPlanInvites', invitations)
      return invitations
    } catch (err) {
      console.error('failed to fetch invites for logged in user', err)
      if (Sentry) Sentry.captureException(err)
      return []
    }
  },
  async fetchPendingPlanInvitationsForBoard(context, boardId) {
    try {
      const planInvitations = await getPendingPlanInvitationsForBoard({ boardId })
      const invitationByPlanId = groupBy(planInvitations, 'planBoardId')
      context.commit('setPlanInvitations', invitationByPlanId)
    } catch (err) {
      console.error('failed to fetch invites for the current board', err)
      if (Sentry) Sentry.captureException(err)
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
  modules: {}
}
