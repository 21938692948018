<template>
  <nav class="absolute left-0 top-4 z-[60] flex w-full flex-shrink-0">
    <section class="mx-[16px] flex flex-1 items-start justify-between gap-4 pb-1">
      <section
        class="flex w-full items-center justify-between gap-2 rounded-lg bg-white p-2 shadow-md"
      >
        <!-- Logo -->
        <div class="flex items-center gap-2">
          <router-link :to="landingUrl">
            <Logo v-if="!logo" class="h-9 w-9" />
            <img v-else :src="logo" class="h-9 max-w-14 object-contain" />
          </router-link>
          <div v-if="isLoggedIn && route.name === 'Landing'">
            <HomeToolbar />
          </div>
        </div>

        <h1
          v-if="route.name === 'Landing'"
          class="hidden text-xl font-medium text-agentnoon-primary-700 min-[885px]:block lg:text-2xl"
        >
          👋 Welcome
        </h1>

        <div class="flex justify-end gap-3">
          <div
            class="group hover:rounded-lg hover:bg-gray-50 hover:text-agentnoon-primary-700"
            :class="{
              'rounded-lg bg-gray-50 text-agentnoon-primary-700': $route.path === landingUrl
            }"
          >
            <router-link
              :to="landingUrl"
              class="flex w-full cursor-pointer items-center whitespace-nowrap px-4 py-2.5 text-left text-sm font-normal text-gray-500 group-hover:text-agentnoon-primary-700"
              :class="{ 'text-agentnoon-primary-700': $route.path === landingUrl }"
            >
              <component
                :is="IconHome"
                class="h-4 w-4 transition-colors duration-200 hover:text-agentnoon-primary-700"
                :class="{
                  'text-gray-500 group-hover:text-agentnoon-primary-700':
                    $route.path !== landingUrl,
                  'text-agentnoon-primary-700': $route.path === landingUrl
                }"
              />
            </router-link>
          </div>

          <div v-if="!shouldShow && isAdmin" class="relative">
            <div
              class="group hover:rounded-lg hover:bg-gray-50 hover:text-agentnoon-primary-700"
              :class="{
                'rounded-lg bg-gray-50 text-agentnoon-primary-700': route.path.includes('/settings')
              }"
            >
              <router-link
                :to="navigationConfig[MenuItems.Settings].url"
                class="flex w-full cursor-pointer items-center whitespace-nowrap py-2.5 pl-4 pr-4 text-left text-sm font-normal text-gray-500"
                :class="{ 'text-agentnoon-primary-700': route.path.includes('/settings') }"
              >
                <component
                  :is="CogIcon"
                  class="h-4 w-4 transition-colors duration-200 hover:text-agentnoon-primary-700"
                  :class="{
                    'text-gray-500 group-hover:text-agentnoon-primary-700':
                      !route.path.includes('/settings'),
                    'text-agentnoon-primary-700': route.path.includes('/settings')
                  }"
                />
              </router-link>
            </div>
          </div>

          <div
            v-if="!shouldShow && isAdmin"
            class="group hover:rounded-lg hover:bg-gray-50 hover:text-agentnoon-primary-700"
            :class="{
              'rounded-lg bg-gray-50 text-agentnoon-primary-700':
                route.path.includes('/invite-access')
            }"
          >
            <router-link
              :to="navigationConfig[MenuItems.Access].url"
              class="flex w-full cursor-pointer items-center whitespace-nowrap px-4 py-2.5 text-left text-sm font-normal text-gray-500"
              :class="{
                'text-agentnoon-primary-700': $route.path === route.path.includes('/invite-access')
              }"
            >
              <component
                :is="UserIcon"
                class="h-4 w-4 transition-colors duration-200 group-hover:text-agentnoon-primary-700"
                :class="{
                  'text-gray-500 group-hover:text-agentnoon-primary-700':
                    !route.path.includes('/invite-access'),
                  'text-agentnoon-primary-700': $route.path.includes('/invite-access')
                }"
              />
            </router-link>
          </div>

          <div class="group hover:rounded-lg hover:bg-gray-50 hover:text-agentnoon-primary-700">
            <a
              href="https://docs.agentnoon.com/help-center/articles"
              target="blank"
              class="flex w-full cursor-pointer items-center whitespace-nowrap py-2.5 pl-4 pr-4 text-left text-sm font-normal text-gray-500"
            >
              <component
                :is="IconHelp"
                class="h-4 w-4 transition-colors duration-200 group-hover:text-agentnoon-primary-700"
              />
            </a>
          </div>

          <div class="group hover:rounded-lg hover:bg-gray-50 hover:text-agentnoon-primary-700">
            <a
              href="mailto:support@agentnoon.com"
              class="flex w-full cursor-pointer items-center whitespace-nowrap py-2.5 pl-4 pr-4 text-left text-sm font-normal text-gray-500"
            >
              <component
                :is="MailIcon"
                class="h-4 w-4 transition-colors duration-200 group-hover:text-agentnoon-primary-700"
              />
            </a>
          </div>

          <Menu v-if="user" as="div" class="relative flex shrink-0 items-center justify-center">
            <MenuButton
              class="hover:ring-agentnoon-primary transform rounded-full transition-all hover:scale-110 hover:ring-2"
            >
              <img
                v-if="userPic"
                :src="userPic"
                class="h-6 w-6 rounded-full"
                :alt="userName"
                @error="
                  $event.target.src =
                    'https://firebasestorage.googleapis.com/v0/b/orgraph-d57a6.appspot.com/o/open_role.png?alt=media&token=5a831181-2239-482a-93e2-0e795a4af0e5'
                "
              />
            </MenuButton>
            <transition
              enter-active-class="transition duration-100 ease-out"
              enter-from-class="transform scale-95 opacity-0"
              enter-to-class="transform scale-100 opacity-100"
              leave-active-class="transition duration-75 ease-in"
              leave-from-class="transform scale-100 opacity-100"
              leave-to-class="transform scale-95 opacity-0"
            >
              <HeadlessMenuItems
                class="absolute right-0 top-12 min-w-[200px] origin-top-right rounded-md bg-white p-2 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                <section class="flex w-full flex-col justify-center gap-1 text-xs text-white">
                  <section
                    class="flex w-full flex-col justify-center gap-1 rounded-md bg-gray-50 p-2"
                  >
                    <div class="flex justify-between gap-2 text-bluedark-700">
                      <span v-if="userName" class="font-medium">{{ userName }}</span>
                      <span
                        v-else
                        class="cursor-pointer font-medium hover:underline"
                        @click="showProfileModal = true"
                      >
                        Set your name here
                      </span>
                      <button @click="showProfileModal = true">
                        <PencilAltIcon class="h-4 w-4 text-gray-700" />
                      </button>
                    </div>
                    <div
                      class="inline-block rounded-full bg-bluedark-50 px-1 py-0.5 font-normal text-bluedark-700"
                    >
                      {{ user.email }}
                    </div>
                  </section>
                  <button
                    class="w-full whitespace-nowrap rounded-lg px-3.5 py-2 text-left text-gray-700 hover:bg-gray-50"
                    @click="redirectToMFA"
                  >
                    {{ hasMFA ? 'Update' : 'Setup' }} MFA
                  </button>
                  <button
                    class="w-full whitespace-nowrap rounded-lg px-3.5 py-2 text-left text-gray-700 hover:bg-gray-50"
                    @click="redirectToLoginMethods"
                  >
                    Update Authentication
                  </button>
                  <button
                    class="w-full whitespace-nowrap rounded-lg bg-error-50 px-3.5 py-2 text-error-700"
                    @click="logout"
                  >
                    Sign out
                  </button>
                </section>
              </HeadlessMenuItems>
            </transition>
          </Menu>
          <ProfileModal
            :show="showProfileModal"
            @close="showProfileModal = false"
            @handle-submit="handleSubmitProfile"
          />
        </div>
      </section>
    </section>
  </nav>
</template>

<script setup>
import HomeToolbar from '@/components/HomeToolbar.vue'
import Logo from '@/assets/Logo/Logomark.svg'
import AnalyticsIcon from '@/assets/untitled-ui/bar-chart-10.svg'
import ChartBreakout from '@/assets/untitled-ui/chart-breakout-square.svg'
import ListItemIcon from '@/assets/untitled-ui/list.svg'
import DataFlowIcon from '@/assets/untitled-ui/dataflow-04.svg'
import IconHome from '@/assets/untitled-ui/home-smile.svg'
import { CogIcon, MailIcon } from '@heroicons/vue/outline'
import IconHelp from '@/assets/untitled-ui/help-circle.svg'
import UserIcon from '@/assets/untitled-ui/users.svg'

import { ref, onMounted } from 'vue'
import { onClickOutside } from '@vueuse/core'

import useBoard from '@/hooks/use-board'
import { useKeyboard } from '@/hooks/use-keyboard.js'
import useNavigation from '@/hooks/use-navigation'
import { MenuItems } from '@/utils/navigationData'
import { useMagicKeys } from '@vueuse/core'
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useProjects } from '@/hooks/use-projects'
import { PLAN_VIEW_PAGES } from '@/lib/PlanViewRoutes'

import { PencilAltIcon } from '@heroicons/vue/outline'
import { Menu, MenuButton, MenuItems as HeadlessMenuItems } from '@headlessui/vue'

import ProfileModal from '@/components/ProfileModal.vue'
const showProfileModal = ref(false)

const { navigationConfig } = useNavigation()
const router = useRouter()
const route = useRoute()
const { boardId } = useBoard()
const { getters, dispatch } = useStore()
const { isUsingInput } = useKeyboard()
const { projects } = useProjects(boardId)
const isLoggedIn = computed(() => getters.isLoggedIn)
const isAppLoading = computed(() => getters.isAppLoading)
const isFetchingBoards = computed(() => getters.isFetchingBoards)
const hasInvitations = computed(() => getters.hasPendingInvitations)
const { boards } = useBoard()
const hasPlanInvites = computed(() => getters.hasUserPlanInvites)
const isAdmin = computed(() => getters.isAdmin(boardId.value))

const landingUrl = computed(() => `/${boardId.value}`)
const logo = computed(() => getters['branding/logo'])
const projectId = computed(() => route.params.projectId)
const project = computed(() => projects.value?.find((project) => project.id === projectId.value))

const views = computed(() => {
  if (!projectId.value)
    return [
      {
        name: MenuItems.OrgPlan,
        icon: DataFlowIcon,
        tooltipText: 'Chart view',
        dataTest: 'main-org-view-button',
        keyboardShortcut: '1'
      },
      {
        name: MenuItems.Directory,
        icon: ListItemIcon,
        tooltipText: 'List view',
        dataTest: 'main-list-view-button',
        keyboardShortcut: '2'
      },
      {
        name: MenuItems.Forecast,
        icon: ChartBreakout,
        dataTest: 'main-forecast-view-button',
        tooltipText: 'Forecast view',
        keyboardShortcut: '3'
      },
      {
        name: MenuItems.Hub,
        icon: AnalyticsIcon,
        dataTest: 'main-hub-view-button',
        tooltipText: 'Workforce hub view',
        keyboardShortcut: '4'
      }
    ]

  return [
    {
      name: 'Plan View',
      icon: DataFlowIcon,
      tooltipText: 'Chart view',
      dataTest: 'main-org-view-button',
      keyboardShortcut: '1',
      handler: () =>
        router.push({ name: 'Plan View', params: { planId: project.value?.planBoardId } })
    },
    {
      name: MenuItems.Directory,
      icon: ListItemIcon,
      tooltipText: 'List view',
      dataTest: 'main-list-view-button',
      keyboardShortcut: '2',
      handler: () =>
        router.push({
          name: 'Plan View',
          params: { planId: project.value?.planBoardId },
          query: { mode: PLAN_VIEW_PAGES.List }
        })
    },
    {
      icon: ChartBreakout,
      dataTest: 'main-forecast-view-button',
      tooltipText: 'Forecast view',
      keyboardShortcut: '3',
      handler: () =>
        router.push({
          name: 'Plan View',
          params: { planId: project.value?.planBoardId },
          query: { mode: PLAN_VIEW_PAGES.Forecast }
        })
    },
    {
      name: MenuItems.Hub,
      icon: AnalyticsIcon,
      dataTest: 'main-hub-view-button',
      tooltipText: 'Workforce hub view',
      keyboardShortcut: '4',
      handler: () =>
        router.push({
          name: 'Plan View',
          params: { planId: project.value?.planBoardId },
          query: { mode: PLAN_VIEW_PAGES.Hub }
        })
    }
  ]
})

const user = computed(() => getters.user)
const userDisplayName = computed(() => getters.userDisplayName)
const hasMFA = computed(() => getters.isMFAEnabled ?? false)

const userPic = computed(() => {
  if (getters.userPic) {
    return getters.userPic
  } else if (userDisplayName.value) {
    return `https://ui-avatars.com/api/?name=${userDisplayName.value}&background=e5e7eb&color=374151`
  } else {
    return `https://ui-avatars.com/api/?name=${
      user.value?.email.split('@')[0]
    }&background=e5e7eb&color=374151`
  }
})

const userName = computed(() => {
  if (userDisplayName.value) {
    return userDisplayName.value.split(' ')[0] || userDisplayName.value
  }
  return null
})

const redirectToMFA = () => {
  // if we will use router.push it will be redirected to Landing because of a watchEffect in LandingPage.vue
  location.href = '/setup-user'
}

const redirectToLoginMethods = () => {
  // if we will use router.push it will be redirected to Landing because of a watchEffect in LandingPage.vue
  location.href = '/update-authentication'
}

const logout = async () => {
  await dispatch('logout')

  if (window.Intercom) window.Intercom('shutdown')
  router.push({ path: '/' })
}

const shouldShow = computed(() => {
  const result =
    !isAppLoading.value &&
    !isFetchingBoards.value &&
    boards.value.length === 0 &&
    !hasPlanInvites.value &&
    !hasInvitations.value

  return result
})

useMagicKeys({
  passive: false,
  onEventFired(e) {
    if (isUsingInput.value || e.type !== 'keydown') return

    const keyIndex = parseInt(e.key) - 1
    if (keyIndex >= 0 && keyIndex < views.value.length) {
      if (projectId.value) {
        views.value[keyIndex].handler()
      } else {
        const menuItem = views.value[keyIndex]

        router.push({ path: navigationConfig.value[menuItem.name]?.url })
      }
    }
  }
})

const handleSubmitProfile = async (displayName) => {
  await dispatch('updateName', { displayName })
}

const isSettingsOpen = ref(false)
const settingsRef = ref(null)

const closeSettings = () => {
  isSettingsOpen.value = false
}

// Add click outside handler
onMounted(() => {
  onClickOutside(settingsRef, closeSettings)
})
</script>
